.maintenance-div {
    display: flex;
    //width: 433px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    .sofortig-maintenance-title {
        color: #070919;
        font-family: Heebo;
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: 34.641px;
        /* 128.299% */
    }

    .sofortig-maintenance-content {
        color: #000;
        font-family: Heebo;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 34.641px;
        /* 192.449% */
        align-self: stretch;
    }
    .rp-maintenance-title {
        color: #070919;

        /* Heading/H4/Medium/Desktop */
        font-family: 'CaslonIonicRegular' !important;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
    }

    .rp-maintenance-content {
        color: #000;

        /* Paragraph/Large/Regular */
        //font-family: "Scto Grotesk A";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        /* 192.449% */
        align-self: stretch;
    }
}