.price-alert {
    display: flex;
    padding: 12px 48px;
    align-items: center;
    align-self: stretch;
    background: var(--primary-500-main, #942FFB);

    .alert-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;

        .alert-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;


            .alert-text {
                color: var(--neutral-50, #FDFCFB);

                /* Paragraph/Small/Regular */
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 142.857% */
            }
        }
    }

    .alert-action {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 6px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 2px;
        border: 1px solid var(--primary-100, #EAD5FE);
        background: var(--generic-white, #FFF);
        margin-right: 9px;

        /* Drop shadow/XSmall */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .action-text {
            color: var(--primary-500-main, #942FFB);
            text-align: center;

            /* Paragraph/Small/Medium */
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 142.857% */
        }
    }
}