.importTaskHeader {
  font-size: 1rem;
  color: black;
}

.importTaskInfo {
  font-size: 0.9rem;
}
.categoryBg {
  background-color: #e6e6ee;
}

.searchInputBg {
  background-color: #6b6799;
}
.nav {
}
.nav .navItem {
  font-family: inherit;
  font-size: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}
