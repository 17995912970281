@import './colors.scss';
@import './mixins.scss';

.va-m {
  vertical-align: middle;
}

.dis-ib {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.plain-btn {
  font-family: SctoGrotesk;
  font-size: 14px;
  font-weight: 500;
  color: $neutral-700;
  border-radius: 2px;
  background: #fff;
  padding: 6px 12px;
  line-height: 1.3;
  border: 1px solid #ceccdd;
  cursor: pointer;
  &:not([disabled]):hover {
    // background: $primary-50;
    color: $primary-500-main;
    border-color: $primary-500-main;
  }
  &:focus {
    outline: none;
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.flex-lr {
  @include flexLR();
}

.flex,
.flex-l {
  display: flex;
  flex-wrap: wrap;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.hidden {
  display: none !important;
}

.flex-r {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.svg-icon {
  vertical-align: middle;
}

.tag {
  padding: 2px 10px;
  border-radius: 24px;
  white-space: nowrap;
}
.tag,
.text {
  display: inline-block;
  font-family: SctoGrotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.tag-warning {
  background: $warning-50;
  color: $warning-500-main;
}
.tag-success {
  background: $success-50;
  color: $success-500-main;
}

.text-warning {
  color: $warning-600;
}

.text-success {
  color: $success-500-main;
}

.thin-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: $primary-50;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-300;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
