// Sidebar light
.app-sidebar {
  &--light {
    background: $sidebar-bg-light;

    &--content {
      background: $sidebar-content-bg-light;
    }

    perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y,
    .ps .ps__rail-y:hover {
      background-color: white ;
    }

    .ps__thumb-y {
      background-color: white ;
    }
  }
}
