.ProgressBar {
    display: flex;
    padding: 20px 0px 12px 0px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: wrap;

    .ProgressStep {
        display: flex;
        width: 200px;
        height: 80px;
        padding: 8px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .ProgressFrame {
            display: flex;
            align-items: center;
            align-self: stretch;
        }

        .TopLine {
            display: flex;
            height: 2px;
            align-items: flex-start;
            flex: 1 0 0;
        }

        .BottomLine {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
        }
    }

    .ProgressStep.Complete {
        .TopLine {
            .Rectangle {
                height: 2px;
                flex: 1 0 0;
                background: var(--primary-500-main, $glowing-purple);
            }
        }

        .Indicator {
            display: flex;
            width: 36px;
            padding: 6px 0px;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            background: var(--primary-500-main, $glowing-purple);
            color: #FFFFFF;
        }

        .BottomLine {
            .Rectangle {
                height: 2px;
                flex: 1 0 0;
                background: var(--primary-500-main, $glowing-purple);
            }
        }
    }

    .ProgressStep.Inactive {
        .TopLine {
            .Rectangle {
                height: 2px;
                flex: 1 0 0;
                background: var(--primary-100, #EAD5FE);
            }
        }

        .Indicator {
            display: flex;
            width: 36px;
            padding: 4px 13.5px 4px 12.5px;
            justify-content: center;
            align-items: center;

            border-radius: 24px;
            border: 2px solid var(--primary-100, #EAD5FE);
            color: var(--primary-500-main, $glowing-purple);
            text-align: center;
        }

        .BottomLine {
            .Rectangle {
                height: 2px;
                flex: 1 0 0;
                background: var(--primary-100, #EAD5FE);
            }
        }

    }

    .Progresslabel {
        align-self: stretch;

        color: var(--neutral-700, #3D3C3B);
        text-align: center;

        /* Paragraph/Small/Medium */
        font-family: "SctoGroteskAUTO" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
    }
}