// Sidebar light

.app-sidebar {
  &--light {
    .sidebar-menu-collapsed {
      li {

        a {
          background: transparent;
          color: rgba($white, .6);

          svg {
            stroke: rgba($white, .6);
            fill: rgba($white, .1);
          }

          &:hover {
            background: rgba($white, .1);
            color: rgba($white, 1);

            svg {
              stroke: rgba($white, 1);
              fill: rgba($white, .3);
            }
          }

          &.active {
            background: $white;
            color: rgba($primary, 1);

            svg {
              stroke: rgba($primary, 1);
              fill: rgba($primary, .3);
            }
          }
        }
      }
    }
  }
}
