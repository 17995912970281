//  Core

.sidebar-navigation {

  .sidebar-menu {
    flex-direction: column;
    align-items: flex-start;
    //padding: 10px 0px;
    flex: 1 0 0;
  }

  .sidebar-bottom {
    flex-direction: column;
    align-items: flex-start;
    //padding: 100px 0 10px 0;
    align-self: stretch;
  }

  .sidebar-menu-box {
    @include border-radius($border-radius-sm);
    margin: ($layout-spacer / 3);
    padding: ($layout-spacer / 3);

    ul li a {
      font-size: $font-size-sm;
      @include border-radius($border-radius-sm);
      padding: 0 ($layout-spacer / 4);

      .sidebar-item-label .badge {
        right: ($layout-spacer / 4);
      }
    }

    ul li ul li a {

      padding: 0 0 0 ($layout-spacer / 2);

      &::after {
        display: none;
      }
    }

    .sidebar-header {
      padding: 0 ($layout-spacer / 4) ($layout-spacer / 4);
    }
  }

  .sidebar-header {
    text-transform: uppercase;
    font-size: $sidebar-header-font-size;
    padding: ($layout-spacer / 3) ($layout-spacer / 2) ($layout-spacer / 4);
    height: initial;
    transition: $transition-base;
    white-space: nowrap;
    margin: 0;
    position: relative;

    &::after {
      position: absolute;
      height: 1px;
      left: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: $transition-base;
      content: '';
    }
  }

  ul {
    @include list-unstyled;
    display: flex;
    flex-direction: column;
    margin: 0;

    li {
      display: flex;
      flex-direction: column;
      position: relative;
      //padding: 1px 0;

      .sidebar-title {
        color: #CECCDD;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        /* 125% */
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      &>a {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        height: $sidebar-navlink-height;
        @media (max-width: 1280px) {
          height: $sidebar-navlink-height-max-width-1280;
         }
         @media (min-width: 1281px) and (max-width: 1600px) {
          height: $sidebar-navlink-height-max-width-1600;
        }
        padding: 0 ($layout-spacer / 2);
        font-size: $sidebar-navlink-font-size;
        white-space: nowrap;
        font-weight: bold;

        .sidebar-icon2 {
          width: 20px;
          min-width: 20px;
          height: 20px;
          display: flex;
          transition: $transition-base;
          align-items: center;
          align-content: center;

          .img {
            width: 20px;
            height: 20px;
          }

          &>* {
            display: block;
            transition: $transition-base;
          }
        }

        .sidebar-item-label {
          height: 22px;
          line-height: 22px;
          transition: $transition-base;
          opacity: 1;
          width: auto;
          visibility: visible;
          overflow: visible;

          .badge {
            position: absolute;
            right: ($layout-spacer / 2);
            height: 22px;
            line-height: 24px;
            top: 50%;
            margin-top: -11px;
            transition: $transition-base;
            opacity: 1;
            visibility: visible;
          }
        }

        .sidebar-item-label2 {
          //color: var(--secondary-50, #E6E6EE);
          transition: $transition-base;
          opacity: 1;
          visibility: visible;
          overflow: visible;
          /* Paragraph/Small/Medium */
          // font-family: Scto Grotesk A;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          padding: 0 0 0 20px;
        }

        .sidebar-icon-indicator {
          transform: rotate(0deg);
          transition: $transition-base;
          //opacity: .4;
          margin-left: auto;
          width: 18px;
          height: 18px;

          &>* {
            max-height: 100%;
            width: 100%;
            display: block;
          }
        }

        &.nav-link-simple {
          .sidebar-icon-indicator-right {
            transform: rotate(0deg) !important;
          }
        }

        .sidebar-icon {
          margin: 0 ($spacer / 1.8) 0 0;
          width: 28px;
          min-width: 28px;
          height: 28px;
          display: flex;
          font-size: $sidebar-icon-font-size;
          transition: $transition-base;
          align-items: center;
          align-content: center;

          &>* {
            max-height: 90%;
            width: 90%;
            display: block;
            transition: $transition-base;
          }
        }

        &.active {
          .sidebar-icon-indicator {
            transform: rotate(-90deg);
          }

          &.nav-link-simple {
            .sidebar-icon-indicator-right {
              transform: rotate(0deg) !important;
            }
          }
        }
      }

      ul {
        overflow: hidden;
        //padding: ($layout-spacer / 4) 0 ($layout-spacer / 3);
        transition: $transition-base;

        li {
          padding: 1px ($layout-spacer / 2);

          a {
            @include border-radius($border-radius);
            font-size: $sidebar-submenu-navlink-font-size;
            position: relative;
            margin: 0;
            font-weight: normal;
            padding: 0 ($layout-spacer / 1.3);
            //padding: 20px 65px;
            height: $sidebar-navlink-inner-height;
            white-space: nowrap;
            transition: transform .2s, height .3s, opacity .3s, margin .3s, color .3s, background-color .3s;
            display: flex;

            .sidebar-noicon-item-text {
              transition: $transition-base;
              opacity: 1;
              visibility: visible;
              overflow: visible;
              //transition: none;
              //color: var(--neutral-50, #FAFAFA);

              /* Paragraph/Small/Regular */
              //font-family: Scto Grotesk A;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              /* 142.857% */
            }

            // &::after {
            //   content: "";
            //   left: 12px;
            //   transition: $transition-base;
            //   width: 8px;
            //   height: 8px;
            //   top: 50%;
            //   position: absolute;
            //   margin: -4px 0 0 -4px;
            //   opacity: 1;
            //   @include border-radius($border-radius-lg);
            // }

            &:hover,
            &.active {
              background: transparent;
            }
          }

          .sidebar-noicon-item {
            @include border-radius($border-radius);
            font-size: $sidebar-submenu-navlink-font-size;
            position: relative;
            margin: 0;
            font-weight: normal;
            padding: 0 ($layout-spacer / 1.3);
            height: $sidebar-navlink-inner-height;
            white-space: nowrap;
            transition: transform .2s, height .3s, opacity .3s, margin .3s, color .3s, background-color .3s;
            display: flex;
            padding: 22px 65px;
            @media (max-width: 1281px) {
              padding: 18px 65px;
             }
             @media (min-width: 1281px) and (max-width: 1600px) {
              padding: 19px 65px;
            }
          }
        }
      }
    }

    .title-box {
      display: flex;
      padding: 20px;
      align-items: flex-start;
      gap: 10px;
      @media (max-width: 1280px) {
       padding: 10px 20px;
      }
      @media (min-width: 1281px) and (max-width: 1600px) {
        padding: 13px 20px;
      }
    }

    .line-nav-bar {
      display: flex;
      padding: 12px 20px;
      align-items: center;
    }
  }
}

.notification {
  border-radius: 5px;
  border: 0.8px solid #3B3E66;
  background: #2B1D4B;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 278px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
  margin-left: 20px;

  .notification-box {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .notification-content {
      color: #A8A6BF;
      font-family: Heebo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 162.5% */
    }

    .notification-link {
      color: #F5F5F5;
      font-family: Heebo;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      text-decoration-line: underline;
    }
  }

}
// Themes

@import "themes/dark";
@import "themes/light";

// Modifiers

@import "modifiers/collapsed";