$main-bg-color: #f5f5f5;

$primary-50: #f4eaff;
$primary-100: #ead5fe;
$primary-200: #d4acfd;
$primary-300: #bf82fd;
$primary-600: #7626c9;
$primary-500-main: #942ffb;

$warning-50: #fffbeb;
$warning-400: #fbbf24;
$warning-500-main: #f59e0b;
$warning-600: #d97706;

$secondary-50: #e6e6ee;
$secondary-100: #ceccdd;
$secondary-200: #9a9cbb;
$secondary-300: #6b6799;
$secondary-400: #393577;
$secondary-500: #080255;

$destructive-50: #fef2f2;
$destructive-200: #fecaca;
$destructive-500-main: #ef4444;
$destructive-600: #dc2626;

$generic-white: #fff;

$success-50: #f0fdf4;
$success-500-main: #22c55e;

$neutral-500: #aca8a5;
$neutral-600: #62605e;
$neutral-700: #3d3c3b;
$neutral-800: #252423;
$neutral-900: #0c0c0c;
