@font-face {
  font-family: 'SctoGroteskAUTO';
  src: local('SctoGroteskAUTO'),
    url('./../../fonts/SCTO_GROTESK_A/Scto-Grotesk-A-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('./../../fonts/inter/Inter-Thin-BETA.otf') format('otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../../fonts/inter/Inter-Light-BETA.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../../fonts/inter/Inter-Regular.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../../fonts/inter/Inter-SemiBold.otf') format('otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../../fonts/inter/Inter-Bold.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SctoGrotesk';
  src: url('./../../fonts/SCTO_GROTESK_A/Scto-Grotesk-A-Light.woff')
    format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SctoGrotesk';
  src: url('./../../fonts/SCTO_GROTESK_A/Scto-Grotesk-A-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SctoGrotesk';
  src: url('./../../fonts/SCTO_GROTESK_A/Scto-Grotesk-A-Medium.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SctoGrotesk';
  src: url('./../../fonts/SCTO_GROTESK_A/Scto-Grotesk-A-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CaslonIonicRegular';
  src: local('CaslonIonicRegular'),
    url('./../../fonts/caslon_ionic/CaslonIonic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'CaslonIonicRegular';
  src: local('CaslonIonicRegular'),
    url('./../../fonts/caslon_ionic/CaslonIonic-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.Toastify__toast.default-toast {
  min-height: 50px;
  background-color: #080255;
  color: #fff;
}
.Toastify__toast.default-toast.Toastify__toast {
  padding: 10px 20px;
}
.Toastify__toast.default-toast .Toastify__toast-body {
  padding: 0;
}
.Toastify__toast.default-toast .default-toast-content {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
}
.Toastify__toast.default-toast .default-toast-close {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  &:hover {
    background-color: #393577;
  }
}

.font-face-ci {
  font-family: 'CaslonIonicRegular' !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  color: var(--neutral-900, #0c0c0c) !important;
}

.font-face-sg {
  font-family: 'SctoGroteskAUTO' !important;
}

.font-face-ci-only {
  font-family: 'CaslonIonicRegular' !important;
}

.alert-neutral {
  background-color: #fffbeb !important;
  color: #92400e !important;
}

.alert-white {
  background-color: #fdfcfb !important;
}

.sub-title {
  font-family: 'SctoGroteskAUTO';
  color: #0c0c0c;
}

.link-style {
  color: #942ffb;
  font-weight: 400 !important;
  text-decoration: underline;
}

/* common start */
.rounded-lg {
  border-radius: 0.5rem;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.gap-1 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 1.5rem;
}
.gap-col-3 {
  column-gap: 1.5rem;
}
.gap-row-3 {
  row-gap: 1.5rem;
}