@mixin flexLR() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flexLeft($gap: 0) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $gap;
}

@mixin square($size) {
  width: $size;
  height: $size;
}
