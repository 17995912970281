.wrapper {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1163;
    display: block;
    height: 100vh;
}

.content {
    position: absolute;
    right: -5px;
    top: 82px
}

.btn {
    max-width: 3.8rem;
}