// Sidebar menu light color scheme
.app-sidebar {
  &--light {
    .sidebar-navigation {

      .sidebar-menu-box {
        background: rgba($black, .15);
      }

      .sidebar-header {
        color: rgba($white, .35);
      }

      ul {
        .active-li {

          &>a:active,
          &>a.active {
            color: rgba(#080255, 1);
            border-radius: 6px;
            background: var(--secondary-100, #CECCDD) !important;

            .sidebar-icon-indicator {
              opacity: .7;
            }

            .sidebar-icon {
              &>* {
                opacity: 1;
              }
            }

            .sidebar-icon2 {
              // img {
              //   filter: drop-shadow(#080255 50px 0);
              //   transform: translateX(-50px);

              // }

              svg path {
                stroke: #080255;
              }
            }
          }
        }

        li {
          &>a {
            color: rgba(#E6E6EE, 1);

            .sidebar-icon {
              &>* {
                color: $white;
              }
            }

            .sidebar-icon2 {
              &>* {
                color: $white;
              }
            }
          }

          ul {

            .sidebar-noicon-item {

              &>a:hover {
                border-radius: 6px;
                background: var(--secondary-400, #393577);
              }

              &>a:active {
                color: rgba(#080255, 1);
                //background: rgba($black, .15);
                border-radius: 6px;
                background: var(--secondary-100, #CECCDD) !important
              }
            }
          }


          &>a:hover {
            border-radius: 6px;
            background: var(--secondary-400, #393577);
          }
        }
      }
    }
  }
}

.app-sidebar-collapsed {
  .app-sidebar--light:not(:hover) {
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &::after {
            background: rgba($white, .15);
          }
        }
      }
    }
  }
}