.batch-order-new {
  display: flex;
  width: 768px;
  flex-direction: column;
  gap: 12px;

  .no-hover.btn:hover {
    transform: none;
  }

  .no-hover {
    a:hover {
      color: #3b3e66;
    }
  }

  .py-12 {
    padding: 12px 0;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    align-self: stretch;
  }

  &-subsection {
    display: flex;
    padding: 12px 16px 32px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 4px;
    background: var(--generic-white, #fff);

    .section-1 {
      display: flex;
      padding: 8px 0px 20px 0px;
      align-items: flex-start;
      gap: 10px;

      color: var(--neutral-900, #0c0c0c);
      font-family: 'CaslonIonicRegular' !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 111.111% */
    }

    .section-2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      color: var(--neutral-800, #252423);

      /* Paragraph/Medium/Regular */
      font-family: 'SctoGroteskAUTO' !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */

      .font {
        color: var(--neutral-700, #3d3c3b);
        text-align: center;

        /* Paragraph/XSmall/Medium */
        font-family: 'SctoGroteskAUTO' !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 166.667% */
      }
    }

    .section-3 {
      display: flex;
      padding-top: 32px;
      flex-direction: column;
      gap: 8px;
      align-self: stretch;

      .info {
        color: var(--neutral-800, #252423);

        /* Paragraph/Small/Regular */
        font-family: 'SctoGroteskAUTO' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: end;
  }
}

.secondary-btn {
  padding: 6px 12px !important;
  border: none !important;
  border-radius: 2px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: 'SctoGroteskAUTO' !important;
  color: #bf82fd !important;
  background: var(--primary-50, #f4eaff) !important;
}

.primary-btn {
  padding: 6px 12px !important;
  border: none !important;
  border-radius: 2px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: 'SctoGroteskAUTO' !important;
  color: #fff !important;
  background: var(--primary-300, #bf82fd) !important;
}

.ant-form-item-label {
  color: var(--neutral-900, #0c0c0c) !important;

  /* Paragraph/Small/Medium */
  font-family: 'SctoGroteskAUTO' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  /* 142.857% */
}

.order-tooltip-icon {
  width: 20px !important;
  height: 20px !important;
}

.default-font {
  font-family: 'SctoGroteskAUTO';
}

// .filter-bottom-end {
//     display: flex;
//     justify-content: end;
// }

.error-border {
  border: 1px solid red !important;
}

.batch-order {
  display: flex;
  width: 928px;
  min-width: 250px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  .batch-order-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    align-self: stretch;

    color: var(--neutral-900, #0c0c0c);
    /* Heading/H4/Regular/Mobile */
    font-family: 'CaslonIonicRegular';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
  }

  .batch-order-bottom {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
